// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/tile.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background_ps\\+Y5,.container_aL1g-,.foreground_LrmDp{clip-path:inset(0 0 0 0 round var(--border-radius-medium));display:flex;height:100%;position:absolute;width:100%;z-index:1}.background_ps\\+Y5{background-color:var(--overlay-color-background);opacity:var(--overlay-opacity-background)}.foreground_LrmDp{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-origin:top left;background-repeat:repeat;opacity:var(--overlay-opacity-foreground)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background_ps+Y5",
	"container": "container_aL1g-",
	"foreground": "foreground_LrmDp"
};
module.exports = ___CSS_LOADER_EXPORT___;
