// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_0Qwpm{height:100%;margin-right:1rem;min-width:18rem;width:25%}.container_0Qwpm,.keyFigures_DNMwE{display:flex;flex-direction:column}.keyFigures_DNMwE{padding:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_0Qwpm",
	"keyFigures": "keyFigures_DNMwE"
};
module.exports = ___CSS_LOADER_EXPORT___;
