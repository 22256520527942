
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";

import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "~/mapbox.js";
import { ResizeSensor } from "css-element-queries";

export default {
  components: { MglMap, MglMarker },

  props: {
    center: {
      type: Array,
      default: () => [12.53770611221422, 55.64820730026961],
    },

    zoom: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      accessToken: "pk.eyJ1IjoiZXN0YWlkIiwiYSI6ImNrd2tvbnh5czF1OG8ydnF2d3MyZmR6dHMifQ.gtGl1xbb_cYlZFVqVszWmg",
      mapStyle: "mapbox://styles/estaid/cldyzq96l002t01nnvsnt0yh9",
      resizeSensor: null,
    };
  },

  created() {
    this.mapbox = Mapbox;
  },

  mounted() {
    var mapElement = this.$refs.mapcontainer;

    this.resizeSensor = new ResizeSensor(mapElement, () => {
      this.map?.resize();
    });
  },

  beforeDestroy() {
    this.resizeSensor?.detach();
  },

  methods: {
    onMapLoaded(event) {
      this.map = event.map;
    },

    getPinColor() {
      return getComputedStyle(document.documentElement).getPropertyValue("--color-purple-500").replace(" ", "");
    },
  },
};
