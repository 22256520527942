import { render, staticRenderFns } from "./Property.vue?vue&type=template&id=5e0acfce"
import script from "./Property.vue?vue&type=script&lang=js"
export * from "./Property.vue?vue&type=script&lang=js"
import style0 from "./Property.vue?vue&type=style&index=0&id=5e0acfce&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EntityCard: require('/app/components/EntityCard/components/EntityCard.vue').default})
