
export default {
  inheritAttrs: false,

  props: {
    colorBackground: {
      type: String,
      required: true,
    },

    colorForeground: {
      type: String,
      required: true,
    },

    opacityBackground: {
      type: String,
      required: true,
    },

    opacityForeground: {
      type: String,
      required: true,
    },
  },
};
