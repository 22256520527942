
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    byline: {
      type: String,
      default: "",
    },
  },
};
