
export default {
  inheritAttrs: false,

  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    colorName() {
      switch (this.status.toLowerCase()) {
        case "completed":
          return "green";

        case "partial":
          return "yellow";

        default:
          return "black";
      }
    },

    message() {
      switch (this.status.toLowerCase()) {
        case "completed":
          return "COMPLETED";

        case "partial":
          return "PARTIAL";

        case "in_progress":
          return "IN PROGRESS";
        default:
          return "–";
      }
    },
  },
};
