
import PropertyCardSkeleton from "~/components/EntityCard/PropertyCardSkeleton.vue";

import EntityCard from "../../components/EntityCard/components/EntityCard.vue";
export default {
  components: { PropertyCardSkeleton, EntityCard },

  props: {
    colorWidgetBackground: {
      type: String,
      default: "--color-white",
    },

    colorWidgetForeground: {
      type: String,
      default: "--color-purple-900",
    },

    colorWidgetSeperatorBackground: {
      type: String,
      default: "--color-purple-100",
    },
  },
};
